<template>
  <div>
    <b-modal
      :id="modalId"
      :title="modalTitle"
      centered
      title-class="font-22"
      hide-footer
      :no-close-on-backdrop="true"
      @hidden="clearForm"
    >
      <b-form @submit.prevent="handleSubmit">
        <b-form-group
          label="Text"
          label-for="text"
        >
          <b-form-textarea
            id="text"
            v-model="form.text"
            rows="5"
            max-rows="6"
            required
          />
        </b-form-group>
        <div class="d-flex justify-content-end">
          <b-button
            type="submit"
            variant="primary"
          >
            {{ buttonText }}
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <div class="d-flex justify-content-end mb-3">
      <b-button
        variant="primary"
        @click="onAddButtonClick"
      >
        Add Note
      </b-button>
    </div>
    <b-list-group>
      <b-list-group-item
        v-for="note in notes"
        :key="note.id"
      >
        <p class="note-text text-dark">{{ note.text }}</p>
        <div class="d-flex align-items-center justify-content-between">
          <span>Posted at {{ dateFormatter(note.created_at) }}</span>
          <div class="note-actions">
            <i
              class="bx bx-pencil text-warning font-size-16 mr-2"
              @click="onEditIconClick(note)"
            />
            <i
              class="bx bx-trash text-danger font-size-16"
              @click="onDeleteIconClick(note.id)"
            />
          </div>
        </div>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import moment from 'moment';
import { FORM_MODE } from '@/utils/constants';

const dateFormatter = (date) => moment(date).format('DD.MM.YYYY HH:mm')

export default {
  name: 'NotesTab',
  data() {
    return {
      formMode: '',
      dateFormatter,
      modalId: 'employee-note-modal',
      form: {
        text: ''
      },
      employeeId: null,
      currentNoteId: null
    }
  },
  mounted() {
    if (this.$route.params.id) {
      this.employeeId = this.$route.params.id;
      this.$store.dispatch('employees/getEmployeeNotes', this.employeeId);
    }
  },
  methods: {
    clearForm() {
      this.form.text = '';
    },
    async handleSubmit() {
      if (this.formMode === FORM_MODE.CREATE) {
        await this.$store.dispatch('employees/createEmployeeNote', {
          id: this.employeeId,
          data: this.form
        });
      } else {
        await this.$store.dispatch('employees/updateEmployeeNote', {
          id: this.currentNoteId,
          data: this.form
        });
        this.currentNoteId = null;
      }
      await this.$store.dispatch('employees/getEmployeeNotes', this.employeeId);
      this.$bvModal.hide(this.modalId);
    },
    onAddButtonClick() {
      this.formMode = FORM_MODE.CREATE;
      this.$bvModal.show(this.modalId);
    },
    onEditIconClick(note) {
      this.form.text = note.text;
      this.currentNoteId = note.id;
      this.formMode = FORM_MODE.EDIT;
      this.$bvModal.show(this.modalId);
    },
    onDeleteIconClick(id) {
      this.$bvModal.msgBoxConfirm('Delete this note?', {
        title: 'Please Confirm',
        okVariant: 'danger',
        okTitle: 'Yes',
        cancelTitle: 'No',
        footerClass: 'p-2',
        hideHeaderClose: false,
        centered: true
      })
        .then(async (value) => {
          if (value) {
            await this.$store.dispatch('employees/deleteEmployeeNote', id);
            await this.$store.dispatch('employees/getEmployeeNotes', this.employeeId);
          }
        })
    }
  },
  computed: {
    notes() {
      return this.$store.state.employees.employeeNotes;
    },
    modalTitle() {
      return this.formMode === FORM_MODE.CREATE ? 'Create Note' : 'Edit Note';
    },
    buttonText() {
      return this.formMode === FORM_MODE.CREATE ? 'Create' : 'Save';
    },
  }
}
</script>

<style lang="scss" scoped>
  .note-text {
    white-space: pre-line;
  }
  .note-actions {
    height: 16px;
    i {
      cursor: pointer;
    }
  }
</style>
