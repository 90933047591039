<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-4">
      <h3>Contracts</h3>
    </div>
    <div
      v-if="contracts && contracts.length"
      class="table-responsive"
    >
      <table class="table table-hover mb-0">
        <thead class="thead-light">
          <tr>
            <th class="id-column"><div>#</div></th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>Status</th>
            <th>On Probation</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(item, index) in list"
            :key="item.id"
          >
            <th class="id-column"><div>{{ index + 1 }}</div></th>
            <td>{{ convertDateToFormat(item.start_date, 'DD.MM.YYYY') }}</td>
            <td>{{ convertDateToFormat(item.end_date, 'DD.MM.YYYY') }}</td>
            <td>{{ status(item) }}</td>
            <td>{{ item.on_probation ? 'Yes' : 'No' }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex align-items-center justify-content-between mb-4">
      <h3>Documents</h3>
    </div>
    <div
      v-if="documents && documents.length"
      class="table-responsive"
    >
      <table class="table table-hover mb-0">
        <thead class="thead-light">
        <tr>
          <th class="id-column"><div>#</div></th>
          <th>Title</th>
          <th>Note</th>
        </tr>
        </thead>
        <tbody>
        <tr
          v-for="(item, index) in documents"
          :key="item.id"
        >
          <th class="id-column"><div>{{ index + 1 }}</div></th>
          <td style="width: 300px" class="title"><a target="_blank" :href="item.link">{{ item.title }}</a></td>
          <td><div v-html="note(item.note)"></div></td>
        </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import { convertDateToFormat } from '@/utils/converters';
export default {
  name: 'Contracts',
  props: ['employeeId', 'contracts', 'documents'],
  components: {
  },
  data() {
    return {
      formMode: null,
      currentContractId: null,
      modalId: 'contract-modal',
      form: {
        startDate: null,
        endDate: null,
        archivedDate: null,
        onPprobation: false
      },
      dismissData: null,
      convertDateToFormat,
    }
  },
  computed: {
    list() {
      return this.contracts
    },
    employee() {
      return this.$store.getters["employees/employee"];
    },
  },
  methods: {
    status(item) {
      const today = new Date(new Date(Date.now()).toISOString().slice(0, 10))
      if (!item.dismissed_date && today <= new Date(item.end_date) && today >= new Date(item.start_date)) {
        return 'Active'
      } else if (item.dismissed_date) {
        return 'Dismiss date: ' + this.convertDateToFormat(item.dismissed_date, 'DD.MM.YYYY')
      } else if (today < new Date(item.start_date)) {
        return 'Inactive'
      } else {
        return 'Expired'
      }
    },
    note (note) {
      return note?.split('\n' || '\r').join('<br>')
    },
  }
}
</script>

<style>
input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
</style>
