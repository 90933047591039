<template>
  <b-form
    class="employee-form"
    @submit="handleSubmit"
  >
    <b-modal
      id="manager-modal"
      title="Assign Manager To Freelancer"
      centered
      title-class="font-22"
      hide-footer
      @show="resetManagerModal"
    >
      <b-form @submit="handleManagerFormSubmit">
        <b-form-group
          label="Manager"
          label-for="manager"
        >
        </b-form-group>
        <div class="d-flex justify-content-end">
          <b-button
            type="submit"
            variant="primary"
          >
            Assign
          </b-button>
        </div>
      </b-form>
    </b-modal>
    <div class="row">
      <div class="col-md-8">
        <AvatarUploader
          v-if="employee"
          :avatar="employee.photo"
          :showMode="true"
          @onChange="handleAvatarChange"
        />
        <b-form-group
          label="Name"
          label-for="name"
        >
          <div class="form-control">{{ employee.name }}</div>
        </b-form-group>
        <b-form-group
          label="Email"
          label-for="email"
        >
          <div class="form-control">{{ employee.email }}</div>
        </b-form-group>
        <b-form-group
          label="Phone"
          label-for="phone"
        >
          <div class="form-control">{{ employee.phone }}</div>
        </b-form-group>
        <template>
          <b-form-group
            label="Department"
            label-for="department"
          >
            <div class="form-control">{{ employee.department ? employee.department.title : '' }}</div>
          </b-form-group>
          <b-form-group
            label="Position"
            label-for="position"
          >
            <div class="form-control">{{ employee.position ? employee.position.title : '' }}</div>
          </b-form-group>
          <b-form-group
            label="Category"
            label-for="category"
          >
            <div class="form-control">{{ employee.category ? employee.category.title : '' }}</div>
          </b-form-group>
          <b-form-group
            label="Locations"
            label-for="locations"
          >
            <div class="form-control">{{ employee.location ? employee.location.title : '' }}</div>
          </b-form-group>
          <b-form-group
            label="Company"
            label-for="companies"
          >
            <div class="form-control">{{ employee.company ? employee.company.title : '' }}</div>
          </b-form-group>
          <b-form-group
            label="Legal status"
            label-for="legal-status"
          >
            <div class="form-control">{{ employee.status }}</div>
          </b-form-group>
          <b-form-group
            label="Payment scheme"
            label-for="payment-scheme"
          >
            <div class="form-control">{{ employee.payment_scheme ? employee.payment_scheme.title : '' }}</div>
          </b-form-group>
        </template>
      </div>
      <div class="col-md-4">
        <h4>Responsibilities and Duties</h4>
        <ul class="list-unstyled">
          <li
            v-for="position in employee.positions"
            :key="position.id"
          >
            {{ position.title }}
            <ul
              v-if="position.duties && position.duties.length"
            >
              <li
                v-for="duty in position.duties"
                :key="duty.id"
              >
                {{ duty.value }}
              </li>
            </ul>
          </li>
        </ul>
        <div
            class="mb-4"
        >
          <div class="d-flex align-items-center justify-content-between mb-3">
            <h5>Payment Managers</h5>
          </div>
          <div
              v-for="(p_manager) in employee.payment_managers"
              :key="p_manager.uid"
              class="d-flex align-items-center justify-content-between mb-1"
          >
            <span>{{ p_manager.name }}</span>
          </div>
          <div v-if="employee?.payment_managers?.length === 0">
            <p>Managers not found</p>
          </div>
        </div>
        <div
            class="mb-4"
        >
          <div class="d-flex align-items-center justify-content-between mb-3">
            <h5>Managers</h5>
          </div>
          <div
              v-for="(manager) in employee.managers"
              :key="manager.uid"
              class="d-flex align-items-center justify-content-between mb-1"
          >
            <span>{{ manager.name }}</span>
          </div>
          <div v-if="employee?.managers?.length === 0">
            <p>Managers not found</p>
          </div>
        </div>
        <div
            class="mb-4"
        >
          <div class="d-flex align-items-center justify-content-between mb-3">
            <h5>Subordinates</h5>
          </div>
          <div
              v-for="(sub) in employee.subordinates"
              :key="sub.uid"
              class="d-flex align-items-center justify-content-between mb-1"
          >
            <span>{{ sub.name }}</span>
          </div>
          <div v-if="employee?.subordinates?.length === 0">
            <p>Subordinates not found</p>
          </div>
        </div>
        <h5>Individual schedule</h5>
        <table class="schedule__calendar calendar">
          <thead class="calendar__header">
          <tr>
            <th
              v-for="(day, index) in days"
              :key="index"
              :class="{'calendar__header-item_active': selects.some(e => e.index === index)}"
              class="calendar__header-item item"
              :id="selects.some(e => e.index === index) ? `tool-tip-cell-${index}` : ''"
            >
              {{ day.name }}
            </th>
          </tr>
          </thead>
        </table>
      </div>
    </div>
  </b-form>
</template>

<script>
import get from 'lodash/get';
import isNull from 'lodash/isNull';
import isBoolean from 'lodash/isBoolean';
import startCase from 'lodash/startCase';
// import Multiselect from 'vue-multiselect';
import { showErrorToast } from '@/utils/toasts';
import AvatarUploader from '@/components/AvatarUploader';
import { convertObjectToFormData } from '@/utils/converters';

export default {
  name: 'EmployeeDetails',
  components: {
    // Multiselect,
    AvatarUploader
  },
  props: ['employee'],
  data() {
    return {
      avatar: null,
      manager: null,
      form: {
        name: this.employee.name,
        email: this.employee.email,
        phone: this.employee.phone,
        managers: get(this.employee, 'managers', []),
        department: this.employee.department_id,
        positions: [],
        category: this.employee.category_id,
        location: this.employee.location_id,
        company: this.employee.company_id,
        legalStatus: this.employee.legal_status_id,
        paymentScheme: this.employee.payment_scheme_id,
        profileType: this.employee.profile ? this.employee.profile.type : null,
        officeKey: this.employee.office_key,
        sso: !isNull(this.employee.user),
        checkinable: this.employee.checkinable,
      },
      days: [
        { name: 'Mon' },
        { name: 'Tue' },
        { name: 'Wed' },
        { name: 'Thu' },
        { name: 'Fri' },
        { name: 'Stu' },
        { name: 'Sun' },
      ],
      selects: [],
      timeZone: null
    }
  },
  mounted() {
    this.employee?.schedule?.forEach((e) => {
      if (!this.selects.some(e => e.index === this.calculateDay(e.orig_day_of_week, 5, e.hour) && e.time === this.calculateTime(e.hour, 5))) {
        this.selects.push({ index: this.calculateDay(e.orig_day_of_week, 5, e.hour), time: this.calculateTime(e.hour, 5) })
      }
      this.timeZone = e.timezone
    })
    // this.$store.dispatch('employees/getEmployees');
    // if (this.positionsOptions.length) {
    //   this.form.positions = this.employee.positions
    //     .map(({ id }) => this.positionsOptions.find((pos) => pos.value === id));
    // }
  },
  watch: {
    positionsOptions() {
      this.form.positions = this.employee.positions
        .map(({ id }) => this.positionsOptions.find((pos) => pos.value === id));
    }
  },
  computed: {
    // ...mapGetters('settings', [
    //   'departmentsOptions',
    //   'categoriesOptions',
    //   'locationsOptions',
    //   'companiesOptions',
    //   'positionsOptions',
    //   'legalStatusesOptions',
    //   'paymentSchemesOptions'
    // ]),
    employees() {
      return this.$store.state.employees.list;
    },
    managers() {
      return this.$store.getters['employees/employeesOptions'].map((item) => {
        if (!item.value) {
          return {
            ...item,
            text: 'Choose'
          };
        }

        return item;
      });
    },
  },
  methods: {
    handleManagerFormSubmit(event) {
      event.preventDefault();
      this.$bvModal.hide('manager-modal');
      const fManager = this.employees.find(({ uid }) => uid === this.manager);
      if (fManager) {
        this.form.managers.push(fManager);
      }
    },
    handleDeleteManager(index) {
      this.form.managers.splice(index, 1);
    },
    resetManagerModal() {
      this.manager = null;
    },
    handleAvatarChange(image) {
      this.avatar = image;
    },
    validateForm() {
      let isValid = true;

      const formEntries = Object.entries(this.form);
      for (let i = 0; i < formEntries.length; i += 1) {
        const [key, value] = formEntries[i];
        if (key !== 'managers') {
          if (Array.isArray(value) && value.length === 0) {
            showErrorToast(`${startCase(key)} is required`);
            isValid = false;
            break;
          }

          if (!isBoolean(value) && !value && !(key === 'email' || key === 'phone')) {
            showErrorToast(`${startCase(key)} is required`);
            isValid = false;
            break;
          }

          if ((key === 'email' || key === 'phone') && this.form.category === 3 && !value && !isBoolean(value)) {
            showErrorToast(`${startCase(key)} is required`);
            isValid = false;
            break;
          }
        }
      }

      return isValid;
    },
    handleSubmit(event) {
      event.preventDefault();
      if (this.validateForm()) {
        const form = {}
        form.name = this.form.name
        if (this.form.phone) {
          form.phone = this.form.phone
        }
        if (this.form.email) {
          form.email = this.form.email
        }
        form.category_id = this.form.category
        form.department_id = this.form.department
        form.location_id = this.form.location
        form.positions_ids = this.form.positions.map(({ value }) => value)
        form.company_id = this.form.company
        form.legal_status_id = this.form.legalStatus
        form.payment_scheme_id = this.form.paymentScheme
        form.profile_type = this.form.profileType
        form.office_key = this.form.officeKey ? 1 : 0
        form.sso_enabled = this.form.sso
        form.checkinable = this.form.checkinable
        form._method = 'PUT'
        const data = convertObjectToFormData(form);
        if (Number(this.form.category) === 6) {
          this.form.managers.forEach((item, index) => {
            data.append(`managers[${index}]`, item.uid);
          });
        } else {
          data.append('managers', '');
        }
        this.$store.dispatch('employees/updateEmployee', {
          data,
          id: this.employee.uid,
        });
        if (this.avatar) {
          this.$store.dispatch('employees/uploadEmployeeAvatar', {
            id: this.employee.uid,
            data: convertObjectToFormData({ photo: this.avatar })
          });
        }
        if (Number(this.form.category) !== 6) {
          this.form.managers = [];
        }
      }
      this.validateForm();
    },
    backToEmployees() {
      this.$router.push('/employees')
    },
    onDeleted() {
      this.$swal.fire({
        title: 'Are you sure?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.$store.dispatch('employees/deleteEmployee', {
            id: this.$route.params.id,
            data: convertObjectToFormData({_method: 'DELETE'})
          })
            .then((response) => {
              if (response && response.code === 200) {
                this.$router.push('/employees');
              }
            })
        }
      })
    },
    calculateTime (hour, timezone) {
      if (hour < Math.abs(timezone) && timezone < 0) {
        return hour + 24 + timezone + 1
      }
      return (hour + timezone) % 24 + 1
    },
    calculateDay (day, timezone, hour) {
      if (hour < Math.abs(timezone) && timezone < 0) {
        return day - 1
      }
      if (hour + timezone + 1 > 23) {
        return (day + 1) % 7
      }
      return day
    },
  }
}
</script>

<style>
  .employee-form i {
    cursor: pointer;
  }
  .item {
    width: 50px;
    height: 24px;
    border: 1px solid #FFFFFF;
    border-radius: 4px;
    text-align: center;
  }
  .calendar__header-item {
    background-color: var(--secondary);
    color: var(--light);
  }
  .calendar__header-item_active {
    background-color: #00be9b;
  }
</style>
